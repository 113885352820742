<template>
  <div id="app">
    <Header/>
    <router-view/>
  </div>
</template>

<script>
import Header from './components/Header';

export default {
  name: 'App',
  components: {
    Header
  },
  data: () => ({
  }),
};
</script>

<style>
@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat"),
   url(./fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
} 
@font-face {
  font-family: "MrDafoe-Regular";
  src: local("MrDafoe"),
   url(./fonts/mr-dafoe/MrDafoe-Regular.ttf) format("truetype");
} 
 
html { box-sizing: border-box; }
*, ::after, ::before { box-sizing: inherit; }
body {
text-rendering: optimizeLegibility;
 margin: 0;
 padding: 0;
 position: absolute;
 max-width: 800px;
 font-family: "Montserrat-Regular";
}

@media (min-width: 800px) {
body {
 left: 50%;
 margin-right: -50%;
 transform: translate(-50%);
}
}	
</style>
