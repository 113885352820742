import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('../views/Main.vue')
  },
  {
    path: '/menu ',
    name: 'menu',
    component: () => import('../components/Menu.vue')
  },
  {
    path: '/viceome',
    name: 'viceome',
    component: () => import('../views/ViceOMe.vue')
  },
  {
    path: '/grafika',
    name: 'Grafika',
    component: () => import('../views/Grafika.vue')
  },
  {
    path: '/sochy',
    name: 'Sochy',
    component: () => import('../views/Sochy.vue')
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import('../views/Kontakt.vue')
  },
  {
    path: '/volna',
    name: 'Volna',
    component: () => import('../views/Volna.vue')
  },
  {
    path: '/obrazy',
    name: 'Obrazy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Obrazy.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
