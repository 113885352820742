<template>
  <div class="top">
    <img alt="Vue logo" src="../assets/logo.jpg">
    <h1><router-link to="/"> M.A. Evžen Hanzalík </router-link></h1>
    <p>malíř</p>  
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped>
.top {
  display: grid;
  grid-template-columns: 100px 600px 100px;
  grid-template-rows: 80px;
  width: 800px;
  background-color:#EEEEEE;
  align-items: center;
  justify-items: center; 
}
.grid-item{
  padding: 20px 10px;
  font-size: 30px;
}
img{
  grid-column: 1 / 2;
  padding-left: 50px;
}
h1{
  grid-column: 2 / 3;
  cursor: pointer;
}
p{
  grid-column: 3 / 4;
  font-size: 20px; 
}
a{
  text-decoration: none;
  color: black;
}
</style>
